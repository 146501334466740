.Top_lan {
  float: right;
  margin-right: 150px;
  margin-top: 0px;
  color: blue;
}
.language-dropdown {
  text-align: center;
}

label {
  margin-right: 10px;
}
.flashing-text {
  text-align: center;
  font-size: 20px;
  font-weight: normal;
  position: relative;
  animation: flash 3s infinite alternate; /* Flashing animation */
}

.text_container {
  width: 50%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  margin-bottom: 70px;
}

.text_header {
  text-align: center;
  color: #37b2ef;
  position: relative;
  z-index: 1;
}

.text_paragraph {
  text-align: center;
  font-size:clamp(1rem,1.5vw,2rem)
}

.text_divider {
  border-bottom: 1px solid #000;
  margin: 0 auto;
  width: 50%;
}
.annualCard{
  background-color:#fff;
  margin-bottom: 30px;
}
.anualSpace{
  width: 100%; 
  justify-content: center;
}
.home_card_container_row{
justify-content: center;
 gap: 30px; 
 flex-wrap: wrap;
}
.home_card_container_row h2{
  
    width: 100%;
    text-align:center;
    color: #3c2e03;

}
.title_line{
  
    display: inline-block;
    border-bottom: 2px solid #3c2e03;
    line-height: 0.1em;
    margin:10px 0 20px;
    width: 100%;

}
.text_header_annual{
  
    background-color:#54873B;
    padding: 0 10px;
    color:#fff;
  
}
.annual_year{
  font-size: 27px;
   border-radius: 15px;
    margin-left: 0;
    padding: 5px 10px;
    display: flex;
    align-items: center;
}
.annual_year img{
  width: 80px;
  margin-right:10px; 
}
.annual_year a{
   color: #fff;
}
.annual_data{
  font-size: 25px;
  margin-left:0;
  color:#fff;
}
.annual_data span{
  color:#fff;
  font-size:27px;
  font-weight: bold;
}

@keyframes flash {
  0%, 100% {
    color: #3c2e03; /* Starting and ending color (red) */
  }
  33% {
    color: #37b2ef; /* Second color (blue) */
  }
  66% {
    color: #00ff00; /* Third color (green) */
  }
}
.topMargin{
  top: 150px;
}
.topnav {
  background-color: #54873B;
  align-items: center;
  text-align: center;
  height: 50px;
}

.topnav a {
  color: #f2f2f2;
  align-items: center;
  padding: 10px 16px 20px 10px;
  text-decoration: none;
  display: inline-block;
  height: 50px;

}

.topnav a:hover {
  background-color: #fff;
  color: #54873B;
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #54873B;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #fff;
  color: #54873B;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}
.dr-icon{
  margin-left: 10px;
}
.fixed-top{
  background-color: #fff;
}
/* .topnav a.active {
  background-color: #04AA6D;
  color: white;
} */

.topnav .icon {
  display: none;
}
.loginform{
margin-top: 150px;
width: 50%;
justify-content: center;
align-items: center;
}

.card:hover{
background-color: #3c2e03;
}
.image_Slide {
  position: relative;
}

.ant-carousel .slick-slide {
  display: flex;
  width: 100%;
  position: relative;
  height: 70vh;
  background: transparent;
  overflow: hidden;
  
}

 .slick-slide img {
  width: 100%;
  height:70vh ;
  object-fit: cover;
}

.text-overlay {
  position: absolute;
  bottom: 20px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  color: white;
  padding: 20px;
  border-radius: 50px;
  font-weight: bold;
  font-size: 1.6em; /* Adjust font size as needed */
  background: linear-gradient(transparent 10%, rgba(0, 0, 0, 0.5) 10%);
  text-align: center;
}

.card_container {
  display: flex;
  flex-direction: column;
  gap: 30px;
 margin: "0 auto";
 justify-items: "center";
 justify-content: center;
 align-items: center;
 text-align: center;
  
}

.card_container_row {
  display: flex;
  gap: 30px;
  margin-left: 350px;
}

.card_container_centered_row {
  justify-content: center;
  display: flex;
  gap: 30px;
}
.card_one {
  width: 35%;
  border: 1px solid #ccc;
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 8px;
  text-align: center;
  font-family: Arial, sans-serif;
  gap: 30px;

}
.card_one img {
  gap: 30px;


}
.card_one p {
  font-size: 16px;
  color: #333;
}
.card-container {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}
.card_number_target {
  display: flex;
  flex-direction: column;
  /* background-color: #f8f9fa; */
  padding: 20px;
  /* border: 1px solid #ddd; */
  border-radius: 5px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  text-align: center;
  width: 45%;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.logo_slide img {
  object-fit: fill;
  width: 50%;
  height: 50vh;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.card_number_target_last {
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 35%;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.card_number {
  background-color: #f8f9fa;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 45%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.cardOneDay:hover{
  cursor: pointer;
  transform: scale(1.06);
  border-radius: 25px;

}
.all_pie{

 display: flex;
}
.oneday{
}
/* #logo h4 {
  margin-top: 25px;
  font-size: 25px;
} */
/* Style for table borders */
.map-title{
  background-color:#008000;
  color:white;
  padding:10px 0;
  text-align: center;
  font-weight: bold;
}
.map-region,.container_click_popup_selected_region,.container_clicked_map{
  color:rgb(97, 95, 95);
  padding:10px 0;
  text-align: center;
  font-weight: bold;

}
.popup_oneday_content{
  margin: 10px;
  padding-left: 10px;
  border-radius: 15px;
  text-align: center;
  width: 0;
  background-color: white;
}
.popup_oneday_content1{
  text-align: center;
  margin: 10px;
  padding: 10px;
  border-radius: 15px;

}
.logo h4 {
  margin-top: 12px;
  overflow: hidden;
  margin-left: 35ch;
  font-size: 18px;
  /* white-space: nowrap; */
  /* animation: typing 2s infinite alternate; */
  color: #007bff; /* Initial color */
  animation: colorChange 3s infinite alternate;
}
.oneday_popup hr{
  background-color: red;
  size: 51px;

}
.container .ant-table-thead > tr > th {
  border: 2px solid #333; /* Custom border for table headers */
  background-color: #e9e9e9; /* Header background color */
  font-size: 16px; /* Header font size */
  color: #000;
}

.container .ant-table-tbody > tr > td {
  border: 1px solid #333; /* Custom border for table cells */
  padding: 10px; /* Padding inside each cell */
}

.container .ant-table-tbody > tr {
  border-bottom: 2px solid #333; /* Custom bottom border for table rows */
}

@media screen and (min-width: 820px) and (max-width: 1500px) {
  .Top_logo {
  display: none;
    
  }
  
  /* #logo h4 {
    font-size: 18px;
  } */
 
}

@media screen and (max-width: 820px) {
  .slide_image img .logo_slide img{
    object-fit: fill;
    width: 100%;
    height: 100vh; /* Full height of the viewport */
    display: block;
    cursor: pointer;
  }
  .text_container{
    width: 100%;
  }

  .footer_VM {
margin-left: 50px;  
text-align: center;
}
  .topnav a:not(:first-child),.dropdown a {
      display: none;
  }
  .topnav a.icon {
      float: right;
      display: block;
      height: 10px;
      margin-top: -30px;
      align-items: center;
      justify-items: center;
      display: flex;
  }
  .loginImagePlaning {
    width:50%;
  }
.footer_VM h3{
  display: flex;
  justify-content: center;
}
.address_footer{
  display: flex;
  flex-direction:row;
  justify-content: center;
  
}

.ant-carousel .slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 40vh;
  background: transparent;
  overflow: hidden;
}

.ant-carousel .slick-slide img {
  width: 100%;
  height: 40vh;
}

.text-overlay {
  position: absolute;
  bottom: 0px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  color: white;
  padding: 10px;
  border-radius: 50px;
  font-weight: bold;
  font-size: 1.0em; /* Adjust font size as needed */
  background: linear-gradient(transparent 10%, rgba(0, 0, 0, 0.5) 10%);
  text-align: center;
}
.address_footer_phone{
  display: flex;
  justify-content: center;
  
  justify-content: center;
}
.Green-legacy-text p{
  display: none;
}
.logo h4 {
  margin-top: 0px;
  margin-left: 10ch;
  font-size: 11px;
 
}
.home_card_container_row{
  justify-content: center;
   gap: 60px; 
   display: flex;
   align-items: center;
   flex-wrap: wrap;
   left: 0;
  }
Table{
  display: none;
}
.mediaTitle p{
  margin-top: 170px;
font-size: 18px;
font-weight: bold;
height:"10vh"
}
.login
.Region_sidebar1{
  overflow-y:scroll;
  max-height: 450px;
  scrollbar-width:thin;
  scrollbar-color: #888 #f1f1f1;
  margin-left: 20px;

}
.all_pie{

  display: inline;
 }
.target_pie, .planted_pie {
  flex: 1 1 100%; /* Make each item take full width on small screens */
  margin: 0; /* Adjust margin for better alignment */
}
.home-map{
  width: 100%;
}
.Region_sidebar_region{
  margin-top: 0px;
  margin-left: 0;
}
.map_info_detail{
  display: none;
}
  .loginImageLogo{
    width: 110px;
  }
  .container_click_popup,.container_clicked_map,.container_click_popup_selected_region{
    margin-top: 10px;
  }
  .loginform{
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .oneday{
    margin-top: 10px;
  }
  
  .topnav.responsive,.dropdown {
      position: relative;
  }
  .topnav.responsive .icon {
      position: absolute;
      margin-top: 10px;
      right: 10px;
      height: 10px;
      align-items: center;
      justify-items: center;
      display: flex;
  }
  /* .topnav{
    padding: 14px 16px;

    height: 70px;
  } */
  .topnav.responsive a ,.dropdown{
      float: none;
      display: block;
      text-align: left;
      background-color: #54873B;
     

  }
  .topnav.responsive a:hover {
    float: none;
    display: block;
    text-align: left;
    color: #54873B;
    background-color: white;

}
}
@media screen and (max-height: 450px) {
  .sidenav_regional {
    padding-top: 15px;
  }
  .sidenav_regional a {
    font-size: 18px;
  }
}
@media only screen and (max-width: 600px) {
  .Top_logo {
    margin-left: 0px;
      display: flex;
      margin-left: 0px;
    
  }
  .Top_logo img {
    width: 250px;
  }
  .Green-legacy-text p{
    float: left;
  }
  .search {
    float: right;
    margin-right: 0px;
    margin-top: -80px;
  }
  .slide img {
    display: none;
  }
 

  .login-link {
    position: fixed;
    right: 10px;
    background-color: #fff;
    padding: 0 20px;
    color: #000;
    text-decoration: none;
    border: solid 1px #fff;
    border-radius: 5px;
  }
  .main_navbar {
    background-color: #37923b;
    display: flex;
    text-align: left;
    justify-content: left;
  }
  .footer {
    background-color: #55ba4a;
    width: 100%;
    height: 350px;
  }
  .row {
    flex-direction: column;
    margin-left: 0px;
  }

  .col-3 {
    width: 100%;
  }
}


table, th, td {
  border: 1px solid white;
  border-collapse: collapse;
}
th {
  background-color: #96D4D4;
}
 td {
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #D6EEEE;
}

/* General navbar styling */
.topnav {
}

.topnav a {
  text-align: center;
}

.topnav .icon {
  display: none;
}

/* Style the active class, and the toggle button, and the responsive class */
.active {
  background-color: #345723;
  border: 1px solid;
}




.responsive a.icon {
  right: 0;
  top: 0;
}



.responsive a.icon {
  display: block;
}


select {
  padding: 1px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}
.top_hr {
  color: red;
}
.Top_logo {
  display: flex;
  height: 70px;
}
.Top_logo img {
}
.Top_logo h3 {
  width: 10px;
  margin-top: 0;
  font-size: 15px;

  color: #54873B;
}
.header-row span {

  color: #fff; /* Initial color */
  animation: colorChange 1s infinite alternate;
}
.header-row h2 {

  color: #fff;
background-color: rgb(110, 53, 6);
padding: 10px 0;

padding:0 25px;
margin-top: 0;
}

.d-lg-flex p {
  margin-right: -50%;
  float: right;
  right: 0;
  margin-right: 0;
  /* border-right: 3px solid; */
  /* white-space: nowrap; */
  /* animation: typing 2s infinite alternate; */
  
}
@keyframes colorChange {
  0% {
    color: white; /* Initial color */
  }
  
  50% {
    color: rgb(238, 12, 12); /* Target color after 2 seconds */

    /*  color: rgb(110, 53, 6);Target color after 2 seconds */
  }
}
.vertical-line {
  width: 3px;
  background-color: #54873B; /* Adjust the color of the line */
  margin-right: 10px; /* Adjust the margin as needed */
}



.main_navbar {
  background-color: #37923b;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main_navbar ul {
  position: relative;
  display: flex;
  flex-direction: row;
}
.main_navbar ul li {
  list-style: none;
}
.main_navbar ul li a {
  list-style: none;
  padding: 0 20px;
  color: #fff;
  text-decoration: none;
}
.main_navbar ul li a:hover {
  background-color: #fff;
  padding-left: 20px;
  color: #000;
  text-decoration: none;
  border: solid 1px #fff;
  border-radius: 5px;
}

.login-link {
  position: fixed;
  right: 50px;
  background-color: #fff;
  padding: 0 20px;
  color: #000;
  text-decoration: none;
  border: solid 1px #fff;
  border-radius: 5px;
}



.slide img {
  width: 100%;
  margin-left: 150px;
  height: 100px;
}
Footer {
  background-color: #34562f;
  height: 350px;
  margin-top: 150px;
}
.footer_VM{
  width: 70%;
}
.container1 {
  margin-left: 250px;
}
.Bottom_logo {
  display: flex;
}
.Bottom_logo img {
  margin-top: 50px;
  width: 50px;
  height: 50px;
}
.Bottom_logo h3 {
  width: 15px;
  margin-top: 0;
  font-size: 12px;
  margin-top: 50px;
  margin-left: 10px;
  color: #fff;
}

.hr {
  color: #fff;
  width: 50%;
  height: 5px;
  background-color: #fff;
}
.Bottom_Address {
}
.Bottom_Address h2 {
  margin-top: 0;
  font-size: 18px;
  margin-top: 50px;
  margin-left: 10px;
  color: #fff;
}
.Bottom_Address h5 {
  margin-top: 0;
  font-size: 13px;
  margin-left: 10px;
  color: #fff;
}
.Bottom_Social {
}
.Bottom_Social h2 {
  margin-top: 0;
  font-size: 18px;
  margin-top: 50px;
  margin-left: 10px;
  color: #fff;
}
.home-map {
}

.home-2 {
  font-size: 20px;
  background-color: #fff;
  border-radius: 8px;
}

.home-2 h3 {
  font-size: 20px;
  text-align: center;
  justify-content: center;
  color: #757070;
}
.home-3 {
  padding-left: 100px;
}
.legend {
  float: left;
  margin-right: px;
  margin-top: 0;
}
.card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  width: 300px;
}

.card-header {
  background-color: #007bff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: #fff;
  padding: 20px;
}

.card-body {
  padding: 20px;
}

.card-footer {
  background-color: #f2f2f2;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 10px 20px;
}

.card-title {
  margin: 0;
}

.card-map {
  background-color: #f2f2f2;
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  width: 270px;
}

.card-map-body {
  padding-top: 1px;
  height: 250px;
}
.card-map-body img {
  border-radius: 30px;
  width: 100%;
  height: 240px;
}
.card-map-footer {
  background-color: #f2f2f2;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 10px 0px;
}

.button {
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  padding: 10px 20px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #0056b3;
}
.ol-control.ol-layerswitcher {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000; /* Ensure the control appears above other elements */
}
#coordinates {
  width: 0;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 250px;
  background-color: white;

  /* transition: 10.5s; */
  border-radius: 5px;

  color: "red";
  border: 0px solid;

  box-shadow: 1px 1px 5px #575555;
  border-radius: 5px;
}
.closebtntab {
  position: absolute;
  top: 15px;


  margin-left: 50px;
  color: rgb(28, 13, 238);
  text-decoration: none;
}

.popup1-close {
  position: absolute;
  top: 0px;
  right: 10px;
  cursor: pointer;
  color: rgb(129, 126, 126);
}

.popup1-close:after {
  content: "✖";
}
.green-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}
.green-lonlat {
  font-size: 20px;
  color: #007bff;
}
.SideBar_Planted_Years {
  width: 200px;
  margin-left: 10px;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid black;
}

.Planted_Year_1,
.Planted_Year_2,
.Planted_Year_3 {
  margin-left: 30px;
}
.SideBar_Planted_Years h4 {
  background-color: #757070;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.SideBar_Planted_Years p {
  font-weight: bold;
}
.seedings_year_img img {
  width: 250px;
  margin-left: 150px;
}
.main_Annual_tree_Container {
  width: 100%;
  /* margin-left: 350px; */
}

.download_button {
  padding: 80px;
}
.col-4 {
  padding: 80px;
}

.sidenav_regional {
  height: auto;
  width: 250px;
  position: absolute;
  z-index: 1;
  top: 150px;
  left: 10px;
  background-color: rgb(248, 245, 245);
  overflow-x: hidden;
  padding-top: 20px;
}

.sidenav_regional a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
}

.sidenav_regional a:hover {
  color: #1b76df;
}

.main_regional {
  margin-left: 250px; /* Same as the width of the sidenav */
  font-size: 28px; /* Increased text to enable scrolling */
  padding: 0px 10px;
}



.sidenav_regional ul li {
  list-style: none;
}
.sidenav_regional ul li a {
  color: black;
  font-size: 16px;
}
.test12 {
  /* background: transparent url("../asset/images/world-map.png") no-repeat center center; */
}
.main_body h3 {
  align-items: center;
  justify-content: center;
  display: flex;
}
.map_container_region {
  border: 1px solid black;
  border-radius: 15px;
}
.sidenav_right {
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;

  border: 1px solid rgb(180, 179, 179);
}
.sidenav_right h5 {
  background-color: #05376b;
  margin: 0 25px;
  color: white;
  display: flex;
  justify-content: center;
  padding: 5px 0;
  border-radius: 5px;
}
.sidenav_right h4 {
  display: flex;
  justify-content: center;
  padding: 5px 0;
}
.CampaignDaysidenav_right {
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid rgb(180, 179, 179);
}
.map_info {
  margin-top: 20px;
}
.map_info ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.map_info li {
  margin-right: 100px; /* Adjust spacing between list items */
  color: #054d99;
}
.map_info p {
  margin-right: 10px; /* Adjust spacing between list items */
  color: #054d99;
  margin-top: -5px;
}
.map_info_detail {
  margin-left: 150px;
}
.map_info_detail p {
  color: black;
  font-weight: bold;
}
.map_info_detail span {
  margin-left: 10px;
  font-weight: normal;
}
.circle-image {
  width: 250px;
}

.dropdown-container {
  display: none;
  background-color: #262626;
  padding-left: 8px;
}
.sidenav a,
.dropdown-btn {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 20px;
  color: #818181;
  display: block;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  outline: none;
}
.icon-chevron-right {
  float: right;
  padding-right: 8px;
}
.side-navigation-panel-select {
  .side-navigation-panel-select-wrap {
    .side-navigation-panel-select-option {
      @apply flex items-center justify-between w-full px-6 py-3 text-gray-700 border-l-2 cursor-pointer;

      &:focus {
        @apply outline-none;
      }

      &:hover {
        @apply bg-gray-100 text-gray-800 border-pink-500;
      }

      .side-navigation-panel-select-option-wrap {
        @apply flex items-center;

        .side-navigation-panel-select-option-text {
          @apply mx-4 font-medium;
        }
      }

      .side-navigation-panel-select-option-icon,
      svg {
        @apply block align-middle;
      }

      .side-navigation-panel-select-option-icon-chevron-up {
      }
      .side-navigation-panel-select-option-icon-chevron-down {
      }
    }

    .side-navigation-panel-select-option-selected {
      @apply text-gray-800 bg-gray-100 border-pink-500;
    }
  }

  .side-navigation-panel-select-inner {
    .side-navigation-panel-select-inner-wrap {
      .side-navigation-panel-select-inner-option {
        @apply flex items-center justify-between px-16 py-2 border-l-2 cursor-pointer text-gray-700;

        &:hover {
          @apply bg-gray-100 text-gray-800 border-pink-500;
        }

        .side-navigation-panel-select-inner-option-wrap {
          @apply flex items-center;

          .side-navigation-panel-select-inner-option-text {
            @apply mx-3 text-sm;
          }
        }
      }

      .side-navigation-panel-select-inner-option-selected {
        @apply text-gray-800 bg-gray-100 border-pink-500;
      }
    }
  }
}
.side_site {
  color: #000;
}
.Title_Annual {
  display: flex;
  justify-content: center;
}

.GLI_sidebar {
  margin-left: 40px;
  cursor: pointer;
}
.GLI_sidebar ul li {
  list-style: none;
  width: 100%;
  color: rgb(35, 159, 242);
  background-color: rgb(255, 255, 255);
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left:20px;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.5); 
}
.GLI_sidebar h6{
  color: rgb(35, 159, 242);
  margin-top: 15px;

}
.GLI_sidebar h3{
  color: rgb(35, 159, 242);
  margin-top: 15px;

}
.article-content h3{
  color: rgb(35, 159, 242);

}
.GLI_sidebar h4{
  color: rgb(123, 123, 123);
  font-size: 17px;
  margin-top: 15px;


}
.mediaTitle p{
  margin: 0 100px;
}
.news_body{
  margin: 0 100px;

}
.read-more {
  display: inline-block;
  margin-top: 15px;
  padding: 1px 20px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}
.read-more:hover {
  background-color: #0056b3;
}
.GLI_sidebar h5{
  color: rgb(125, 126, 127);

}
.container {
  width: 90%;
  margin: 0 auto;
}
.article {
  background-color: #fff;
  margin: 20px 0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.article img {
  width: 200px;
  height: 150px;
  border-radius: 8px;
  margin-right: 20px;
}
.article-content {
  flex: 1;
}
.article-content h3 {
  margin-left: -80px;
  font-size: 30px;
  color: #4491e9;
  text-align: center;
}
.article-content p {
  margin: 10px 0;
  color: #666;
}
.article-date {
  display: flex;
  align-items: center;
  color: #999;
}
.article-date span {
  margin-left: 5px;
}
.Region_sidebar {
  margin-left: -40px;
}
.Region_sidebar ul li {
  list-style: none;
  width: 100%;
  color: white;
  cursor: pointer;
  background-color: rgb(71, 68, 68);
  padding-top: 10px;
  padding-left: 20px;
  padding-bottom: 10px;
}
.title_region strong {
  background-color: rgb(71, 68, 68);
  color: white;
}
.Region_sidebar ul li:hover {
  background-color: rgb(90, 87, 87);
  color: white;
  border-radius: 15px;
  text-decoration: underline;
}
.Region_sidebar Button {
  width: 100%;
  margin-top: 10px;
  color: black;
  background-color: rgb(189, 186, 186);
}
.Home_rightside {
  border: 1px solid black;
}
.Home_rightside p {
  font-size: 18px;
  padding-top: 10px;
  height: 58px;
  font-weight: bold;
}
.Site_data_Right_side {
  border-radius: 25px;
}
.Site_data_Right_side ul {
  margin-top: 10px;
}
.Site_data_Right_side li {
  background-color: #949597;
  text-decoration: none;
  color: white;
  display: flex;
  justify-content: center;
  width: 150px;
  border-radius: 25px;
  padding: 10px;
}
.upload {
  justify-content: center;
  border-radius: 15px;
}
.upload_button {
  color: white;
  background-color: #0056b3;
}
.Regiator_form {
  display: flex;
  justify-content: center;
  background-color: rgb(247, 245, 245);
  margin-top: 20px;
}
.form_registor {
  margin-top: 40px;
}
.RegionalData_detail {
  margin-top: 40px;
  border: 1px solid rgb(192, 190, 190);
}

.RegionalData_detail h3 {
  background-color: #054d99;
  color: white;
  font-size: 18px;
  padding: 15px 0;
}
.search1 {
  display: inline-flex;
  align-items: center;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
}
.search1:is(input, button) {
  background-color: transparent;
  color: inherit;
  border: none;
  outline: none;
}
/* .search1_input{
  width: 10px;
  transition: width 0.5s;
  */

.search1_button {
  display: grid;
  place-items: center;
  width: 25px;
  height: 25px;
  cursor: pointer;
  transition: color 1.2s;
}
.search1:focus-within input {
  width: 200px;
}

.search1_input {
  background-image: url("../asset/images/search-removebg-preview (1).png"); /* Replace 'path_to_your_icon.png' with the path to your icon image */
  background-size: contain; /* Adjust according to your icon's size */
  background-repeat: no-repeat;
  padding-left: 20px; /* Adjust padding to ensure icon visibility */
  float: right;
  right: 0;
}
.search1_input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}
#result {
  background-color: #c2dfc4;

  padding: 20px 0;
  display: none;
}
.data {
  margin: 2% 20%;
  border: 1px solid rgb(187, 185, 185);
  border-radius: 15px;
  padding: 20px;
}
.data_upload {
  margin: 2% 20%;
  border: 1px solid rgb(187, 185, 185);
  border-radius: 15px;
  padding: 20px;
}
.site_option p{
margin-top: -10px;
}
.site-select{
  margin: -50px 0 50px 0;
  background-color: rgb(240, 240, 240);
  width: auto;
}

.upload_sideNav ul li{
  list-style: none;
}

.Region_sidebabr{
 /* Add a fixed height to the div to enable scrolling */
 height: 580px; /* Adjust the height as needed */
 /* Add overflow property to enable scrolling */
 overflow: auto;
  /* box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2); */
}
.Home_leftSide {
height: auto;
margin-left: 20px;
padding: 20px 10px  0 10px;
}
.side_top{
  display: flex;
  margin-top: 0;
  gap: 80px;
}
.Home_leftSide ul li {
list-style: none;  /* margin-bottom: 15px; */
  /* border: 1px solid rgb(161, 160, 160); */

  
}

.Home_leftSide  h3 {
  font-size: 21px;
}


.button-viewmap {
  font-size: 14px;
  color: white;
  background-color: gray;
  border: 1px solid transparent;
  cursor: pointer;
  margin-left: 100px;
  border-radius: 5px;
  font-weight: bold;
}
.button-viewmap a{
  color: white;
}
.button-viewmap a:hover{
font-weight: bold;
color: white;

}

.button-viewmap:hover{
  border-radius: 50px;
}

/* CSS for the score div container */
.score-container {
  /* overflow-y: auto;
  max-height: 520px; */
padding: 10px;
border: 1px solid rgb(204, 202, 202);
margin-left: 10px;
box-shadow: 10px 0px 10px 0px rgba(0,0,0,0.5); /* Offset-X | Offset-Y | Blur-Radius | Spread-Radius | Color */
}
.Home_leftSide img {
  width: 120px;
  height: 120px;
}
.home_right_side_title{
  font-size: 18px;
  text-align: center;
}
.Green-legacy-text{
  color: #54873B;
  margin-right: 15px;
}
.slide {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}
.slide span {
  display: inline-block;
  animation: slide 5s linear 1;
}
@keyframes slide {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}
.legend{

float: left;
/* border: 1px solid transparent;
box-shadow: 10px 0px 10px 0px rgba(1,2.5,2.5,3.5); /* Offset-X | Offset-Y | Blur-Radius | Spread-Radius | Color */
width: 100%; 
margin: 0 10px;
}


.legend h1{
  font-size: 25px;

}
.lang-switch {
  list-style-type: none;
  padding: 0;
}

.lang-switch li {
  display: inline-block;
  margin-right: 10px;
}

.lang-switch li:last-child {
  margin-right: 0;
}

.lang-switch a {
  text-decoration: none;
  color: #333;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.lang-switch a:hover {
  background-color: #f0f0f0;
}
.container_click_popup_selected_region{
  top: 110px;
  position: fixed;
  background-color: white;
  height: 100vh;
  width: 0;
  float: right;
  left: 0;
  border: 1px solid rgb(229, 226, 226);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}


.container_click_popup_Regions_selected_region{
  top: 100px;
  left: 0;
  position: fixed;
  background-color: white;
  height: 100vh;
  width: 0;
  
}
#container_click_popup_selected_region-lat,#container_click_popu_selected_regionp-lon{
  text-align: center;
}
#container_click_popup_selected_region-content{
  color: rgb(89, 90, 91);
  font-size: 14px;
  width:90% ;
  height: 100hv;
  padding: 10px;
  }
  #container_click_popup_selected_region-image img{
    margin: 10px;
    border: 1px solid transparent;
    border-radius: 30px;
    width:50%;
    height: 50px;
   
    }






    .container_click_popup_selected_year{
      top: 100px;
      position: fixed;
      background-color: white;
      height: 100vh;
      width: 0;
      left: 0;
      
    }
    .container_click_popup_Regions_selected_year{
      top: 100px;
      left: 0;
      position: fixed;
      background-color: white;
      height: 100vh;
      width: 0;
      
    }
    #container_click_popup_selected_year-lat,#container_click_popup_selected_year-lon{
      text-align: center;
    }
    #container_click_popup_selected_year-content{
      color: rgb(89, 90, 91);
      font-size: 14px;
      width:90% ;
      height: 100hv;
      padding: 10px;
      background-color: #efefef;
      }
      #container_click_popup_selected_year-image img{
        margin: 10px;
        border: 1px solid transparent;
        border-radius: 30px;
        width:90%;
        height: 120px;
       
        }

        .container_clicked_map{
          top: 110px;
          position:fixed;
          background-color: white;
          height: 100vh;
          width: 0;
          left: 0;
          
        }
  
        #container_clicked_map-lat,#container_clicked_map-lon{
          text-align: center;
        }
        #container_clicked_map-content{
          color: rgb(89, 90, 91);
          font-size: 14px;
          width:90% ;
          height: 100vh;
          }
         





.container_click_popup{
  top: 100px;
  position: fixed;
  background-color: white;
  height: 100vh;
  width: 0;
  
}
.container_click_popup_Regions{
  top: 100px;
  left: 0;
  position: fixed;
  background-color: white;
  height: 100vh;
  width: 0;
  
}
#container_click_popup-lat,#container_click_popup-lon{
  text-align: center;
}
#container_click_popup-content{
  color: rgb(89, 90, 91);
  font-size: 14px;
  width:90% ;
  height: 100hv;
  padding: 10px;
  }
  #container_click_popup-image img{
    margin: 10px;
    border: 1px solid transparent;
    border-radius: 30px;
    width:50%;
    height: 50px;
   
    }
.close-icon{
  width: 0;
  display: none;
}
.close-icon {
  font-size: 30px; /* adjust as needed */
  line-height: 1;
  cursor: pointer;
  color: rgb(201, 198, 198);
  float: right;
  margin-right: 20px;
  font-weight: bold;
}

.close-icon1{
  width: 0;
  display: none;
}
.close-icon1 {
  font-size: 30px; /* adjust as needed */
  line-height: 1;
  cursor: pointer;
  color: rgb(201, 198, 198);
  float: right;
  margin-right: 20px;
  font-weight: bold;
}

.error{
  background-color: red;
  color: white;
  text-align: center;

}

.login {
  /* margin-top: 5%;
  margin-left: 5%;
  margin-right: 5%;
  padding: 2px;
  background-color: rgb(241, 240, 240); */
border:1px solid rgb(241, 241, 241);
border-radius: 5px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}

.container_list{
  margin-top: 10px;
}


  .region_metadata{
    border: 1px solid black;
  }
  .region_metadata ul li{
    list-style: none;
  }
  .table-container {
    text-align: center;
  }
  
  .custom-table {
    width: 80%;
    margin: auto;
    border-collapse: collapse;
    border: 1px solid black;
  }
  
  .custom-table th, .custom-table td {
    border: 1px solid black;
    padding: 8px;
  }
  
  .custom-table th {
    background-color: #f2f2f2;
  }
  
  .custom-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .custom-table tbody tr:hover {
    background-color: #ddd;
  }
  .fullscreen-icon {
    width: 20px;
    height: 20px;
    background-image: url('../asset/images/2023_coverage.png');
    /* Add any additional styling as needed */
  }  
  .metadata_region{
    margin-left: 120px;
    left: 50%;
    display: flex;
    justify-content: center;
  }
  .view_type_select{
    margin-left: 10%;

  }
  .view_type_select h3{
    font-size: 17px;
  }
  .years-select{
    width: 70%;
    height: 30px;
  }
  .map_side{
    margin-top:-50px;
    border: 1px solid black;
  }
  .map_side1{
 
    border: 1px solid black;
    margin-top: 0;
  }
  .Target_title{
    float: right;
    background-color: green;
    color: white;
    margin-top: 10px;
  }
  #container_click_popup-area{
    background-color: rgb(146, 141, 141);
    color: #fff;
    width: 50%;

  }
  .slide_image {
  }
  
  .slide_image img {
    object-fit: cover;
    width: 100%;
    height: 100vh; /* Full height of the viewport */
    display: block;
    cursor: pointer;
  }
  .popup_side_bar{
    overflow-y:scroll;
    max-height: 500px;
    scrollbar-width:thin;
  }
  .Region_sidebar1{
    overflow-y:scroll;
    max-height: 450px;
    scrollbar-width:thin;
    scrollbar-color: #888 #f1f1f1;

  }
  .year{
    font-size: 18px;
  }
  .display_inline{
display: flex;
  }
  .hr_home_side{
    color: red;
    border: 5px solid rgb(151, 153, 151);
  }

  .upload_side{
    background-color: rgb(71, 68, 68);
    padding:  10px 0;
    margin-top: 10px;
    width: 50%;
    text-align:center;
    color: white;
    margin-left: 15px;
    cursor: pointer;
  }
  .even-row {
    font-size: 14px;

  background-color: #d0cccc; /* Specify the background color for even rows */
}

.odd-row {
  font-size: 14px;

  background-color: #d0cccc;}
.column-target_2019,
.column-target_2021,
.column-target_2023 {
  background-color: #d0cccc;}

.column-target_2020,
.column-target_2022,
.column-target_2024 {
  background-color: #d0cccc;}

.column-region{
  font-size: 18px;
  font-weight: bold;
  background-color: rgb(251, 249, 249); /* Specify the background color for other specific columns */


}
.column-planted_2019,
.column-planted_2021,
.column-planted_2023 {
 /* background-color: #b6f2c1; /* Specify the background color for specific columns */
}

.column-planted_2020,
.column-planted_2022,
.column-planted_2024 {
 /* background-color: #b6f2c1; /* Specify the background color for other specific columns */
}
.custom-panel-header {
  color: red;
  font-size: 25px;
}
.Media_leftSide{
  margin-left: 25px;
}
.Media_leftSide ul{
  margin-top: 100px;

}
.Media_leftSide ul li{
  list-style: none;
  row-gap: 10px;
  color: #000;
  width: 80px;
  cursor: pointer;
}
.Media_leftSide ul li:hover{
background-color: #f7f4f4;
}
/* styles.css */
.list-item {
  display: flex;
  align-items: center;
  padding: 10px;
  list-style-type: none;
}

.list-item .anticon {
  margin-right: 8px; /* Adjust spacing between icon and text */
}
/* src/components/RandomNews.css or src/App.css */
.image-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.image-row {
  display: flex; /* Use flexbox to create a row layout */
  gap: 20px; /* Adjust spacing between images */
  flex-wrap: wrap; /* Allow images to wrap to the next line if needed */
}

.image-item {
  width: 300px; /* Adjust width of each image item */
  text-align: center; /* Center-align items */
}

.image-item img {
  width: 100%; /* Ensure images fill their container */
  height: auto; /* Maintain aspect ratio */
}
.form-container {
  display: flex;
  justify-content: space-between;
}

.form-column {
  width: 48%;
}

.form-group p {
  margin-bottom: 0.5rem;
}

.form-control {
  margin-bottom: 1rem;
}
